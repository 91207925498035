<template>
    <div
        tabindex="0"
        :class="[
            'collapse',
            { hide: !state, active: state },
            { hidden_c: show === false },
        ]"
    >
        <header class="header">
            <slot name="header" />
            <icon name="arrow-up" @click.prevent.stop="handleTitleClick" />
        </header>

        <div
            ref="dropContentWrapper"
            :class="['dropContent-wrapper', { hidden_c: show === false }]"
        >
            <div
                ref="dropContent"
                :class="['dropContent', { hidden_c: show === false }]"
            >
                <slot name="dropContent" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        show: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            state: false,
        }
    },

    watch: {
        '$resize.value'() {
            this.updateMaxHeight()
        },

        show() {
            if (this.show === false) {
                this.state = false
            }
        },
    },

    mounted() {
        this.updateMaxHeight()
    },

    methods: {
        handleTitleClick() {
            this.state = !this.state
        },

        updateMaxHeight() {
            const dropContentWrapper = this.$refs.dropContentWrapper
            const dropContent = this.$refs.dropContent

            const height = dropContent.clientHeight
            dropContentWrapper.style.maxHeight = height + 'px'
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/config';

.hidden_c {
    visibility: hidden;
    z-index: -1;
}

.collapse {
    --collapse-time: 0.4s;
    //margin-bottom: 1rem;
    //@include shadow-x-light;
    //background-color: theme('colors.ocean.100');
    color: theme('colors.slate.900');
    // transition: color 0.25s calc(var(--collapse-time) / 2),
    //     background-color 0.25s calc(var(--collapse-time) / 2);

    &.hide {
        .header {
            .icon {
                transform: translate(0%, -50%) rotate(180deg);
            }
        }

        .dropContent-wrapper {
            visibility: hidden;
            max-height: 0 !important;
            transition-delay: 0s, var(--collapse-time);
        }

        .dropContent {
            opacity: 0;
        }
    }
}

.header {
    position: relative;

    .icon {
        right: 0.75rem;
        top: 50%;
        width: 1.75rem;
        transform: translate(0%, -50%) rotate(0deg);
        transform-origin: center center;
        transition: transform var(--collapse-time) 0s
            theme('transitionTimingFunction.easeOutQuad');
    }
}

.dropContent-wrapper {
    visibility: visible;
    overflow: hidden;

    transition: max-height var(--collapse-time) 0s
            theme('transitionTimingFunction.easeOutQuad'),
        visibility 0s 0s;
}

.dropContent {
    opacity: 1;
    transition: opacity calc(var(--collapse-time) / 2);
    display: inline-block;
}
/*
@screen sm {
    .header {
        @apply text-lg;
    }

    .dropContent {
        @apply text-md;
        @apply font-normal;
    }
}

@screen md {
    .header {
        padding: 1rem 1.5rem;

        .icon {
            right: 1rem;
        }
    }

    .dropContent {
        padding: 0.125rem 1.5rem 1rem;
        @apply font-semibold;
    }
}

@screen lg {
    .header {
        padding: 1.5rem 2rem;
        @apply text-xl;

        .icon {
            right: 1.5rem;
        }
    }

    .dropContent {
        padding: 0.125rem 2rem 1.5rem;
        @apply font-normal;
    }
}
*/
</style>
