import { defineNuxtPlugin } from '#app'
import { START_LOCATION } from 'vue-router'

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.$router.options.scrollBehavior = (to, from, savedPosition) => {
        if (from === START_LOCATION || to.name === from.name) {
            return
        }

        const position = savedPosition || { top: 0 }

        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(position)
            }, 400)
        })
    }
})
