import _filter from 'lodash/filter.js'

const isTouchDevice =
    'ontouchstart' in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0

document.documentElement.classList.add(
    isTouchDevice ? 'touch-device' : 'no-touch-device',
)

// check_webp_feature:
//   'feature' can be one of 'lossy', 'lossless', 'alpha' or 'animation'.
//   'callback(feature, result)' will be passed back the detection result (in an asynchronous way!)
function checkWebpFeature(feature) {
    const kTestImages = {
        lossy: 'UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA',
        lossless: 'UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==',
        alpha: 'UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==',
        animation:
            'UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA',
    }

    return new Promise((resolve) => {
        const img = new Image()
        img.onload = function () {
            const result = img.width > 0 && img.height > 0
            resolve(result)
        }
        img.onerror = function () {
            resolve(false)
        }
        img.src = 'data:image/webp;base64,' + kTestImages[feature]
    })
}

function checkWebpFeatures(features) {
    return Promise.all(features.map((f) => checkWebpFeature(f))).then(
        (results) => {
            return _filter(results).length === features.length
        },
    )
}

let supportsWebP = false
checkWebpFeatures(['lossy', 'lossless', 'alpha'])
    // eslint-disable-next-line
    .catch((error) => {
        return false
    })
    .then((result) => {
        document.documentElement.classList.add('detected')

        if (result) {
            document.documentElement.classList.add('webp')
            supportsWebP = true
        } else {
            document.documentElement.classList.add('no-webp')
        }
    })

// Test via a getter in the options object to see if the passive property is accessed
let supportsPassiveEventListeners = false
try {
    const opts = Object.defineProperty({}, 'passive', {
        get: function () {
            supportsPassiveEventListeners = true
            return undefined
        },
    })
    window.addEventListener('testPassive', null, opts)
    window.removeEventListener('testPassive', null, opts)
} catch (e) {
    // unsupported
}

export default defineNuxtPlugin((nuxtApp) => {
    const app = nuxtApp.vueApp
    app.config.globalProperties.$features = {
        isTouchDevice,
        supportsPassiveEventListeners,
        get supportsWebP() {
            return supportsWebP
        },
    }
})
