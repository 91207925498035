import _debounce from 'lodash/debounce.js'
import _remove from 'lodash/remove.js'
import _each from 'lodash/each.js'

import { ref } from 'vue'

export default defineNuxtPlugin((nuxtApp) => {
    const app = nuxtApp.vueApp
    const listeners = []

    app.config.globalProperties.$onResize = (vm, callback) => {
        if (!callback) return

        if (vm.resizeCallback) {
            _remove(listeners, vm.resizeCallback)
        }

        vm.resizeCallback = callback
        listeners.push({ vm, callback })
    }

    app.config.globalProperties.$resize = ref(getData())

    function getData(eventName = '') {
        return {
            name: eventName,
            width: document.documentElement.clientWidth,
            height: document.documentElement.clientHeight,
            orientation:
                document.documentElement.clientWidth >
                document.documentElement.clientHeight
                    ? 'landscape'
                    : 'portrait',
        }
    }

    function handleResize(eventName) {
        _remove(listeners, ({ vm }) => vm.isUnmounted)

        app.config.globalProperties.$resize.value = getData(eventName)

        _each(listeners, ({ callback }) => callback())
    }

    window.addEventListener(
        'resize',
        _debounce(() => handleResize('resize'), 150),
    )
    window.addEventListener(
        'orientationchange',
        _debounce(() => handleResize('orientationchange'), 500),
    )
})
