import { defineStore, acceptHMRUpdate } from 'pinia'

import {
    object,
    string,
    pattern,
    nonempty,
    refine,
    partial,
    validate,
} from 'superstruct'

import _debounce from 'lodash/debounce.js'
import _omit from 'lodash/omit.js'
import _map from 'lodash/map.js'
import _each from 'lodash/each.js'
import _pick from 'lodash/pick.js'
import _find from 'lodash/find.js'
import _findIndex from 'lodash/findIndex.js'
import _findKey from 'lodash/findKey.js'
import _filter from 'lodash/filter.js'
import _assign from 'lodash/assign.js'
import _cloneDeep from 'lodash/cloneDeep.js'
// import _defaults from 'lodash/defaults.js'
import Bugsnag from '@bugsnag/js'
import dayjs from 'dayjs'
import useUserStore from './user.js'
import { Enums, EnumsLegacy } from '~/config/Enums'

const OrderSettings = object({
    zipCode: pattern(string(), /^\d{5}$/),
    city: nonempty(string()),
    customerGroup: refine(
        string(),
        'customerGroup',
        (value) => Enums.customerGroups[value] !== undefined,
    ),
    tourType: refine(
        string(),
        'tourType',
        (value) => Enums.tourTypes[value] !== undefined,
    ),
    partner: refine(
        string(),
        'partner',
        (value) => Enums.partners[value] !== undefined,
    ),
    b2bCustomerType: refine(
        string(),
        'b2bCustomerType',
        (value) => Enums.b2bCustomerType[value] !== undefined,
    ),
})

const OrderSettingsPartial = partial(OrderSettings)

const useOrder = defineStore(
    'order',
    () => {
        const serverTools = useServerTools()
        const config = useRuntimeConfig()
        const nuxtApp = useNuxtApp()
        const baseURL = config.public.sources.api.url
        const version = ref(config.public.version)
        const userStore = useUserStore()
        const log = useLog('order-store')

        const settings = ref({
            zipCode: '',
            city: '',
            customerGroup: Enums.customerGroups.private,
            tourType: Enums.tourTypes['2ndhandgoods'],
            partner: Enums.partners.recyclehero,
            b2bCustomerType: Enums.b2bCustomerType.general,
        })

        const validatedSettings = ref({
            valid: false,
            errors: false,
        })
        const isSettingsLocationValid = ref(false)
        const isLocationSupported = ref(false)
        // CORE-1150: used to determine LocationNotSupportedModal or CapacityDialog
        const isCurrentlyBookable = ref(true)

        let lastZipCode = ''
        const isLocationFetched = ref(false)

        const zipcodeIsInAtLeastOneBasearea = ref(false)
        const zipcodeIsSupported = ref(false)
        const baseareaName = ref('')
        const baseareaCode = ref('')
        const isParcelShippingCarrier = ref(false)
        const productTypes = ref([])
        const supportedProducts = ref([])
        const addressValidity = ref({})

        const dataDefaults = {
            pickupDate: '',
            pickupTimeSlot: null,
            /* contains following object later {
                timeFrameStart: '',
                timeFrameEnd: '',
                carrier: '',
            },
            */

            products: [],
            parcelOption: Enums.parcelOptions.parcelShop,

            pickupAddress: {
                firstName: '',
                lastName: '',
                organization: '',
                street: '',
                streetNumber: '',
                zipCode: '',
                city: '',
                additionalInfo: '',
            },

            separateInvoiceAddress: false,
            invoiceAddress: {},

            contact: {
                email: '',
                phone: '',
            },
            account: {
                password: '',
            },
            approvals: {
                newsletter: false,
                dataProtection: false,
                electronicsConfirmation: false,
            },
            campaign: {
                name: '',
                id: '',
            },
        }

        const data = ref(_cloneDeep(dataDefaults))

        const calculatedPrice = ref({
            brutto: 0,
            bruttoAfterExpiration: 0,
            fee: false,
            netto: 0,
            nettoAfterExpiration: 0,
            taxRate: 19,
        })

        const changedSettings = computed(() => {
            const changed = {}

            _each(settings.value, (value, key) => {
                if (value) {
                    changed[key] = value
                }
            })

            return changed
        })

        const isOrderArea = computed(
            () =>
                nuxtApp.$router.currentRoute.value.name &&
                nuxtApp.$router.currentRoute.value.name.substring(0, 5) ===
                    'order',
        )

        const orderEdited = ref(false)
        watch(
            data,
            () => {
                try {
                    if (
                        data.value.products.length !==
                        dataDefaults.products.length
                    ) {
                        orderEdited.value = true
                    }
                } catch (error) {
                    log('failed to determine if order was edited')
                    console.warn(error)

                    Bugsnag.notify(error, (event) => {
                        event.context = 'order-store.order-edited'
                    })
                }
            },
            { deep: true, immediate: true },
        )

        const parcelShopBooking = computed(() => {
            if (
                isParcelShippingCarrier.value &&
                data.value.parcelOption === Enums.parcelOptions.parcelShop
            ) {
                return true
            }
            return false
        })

        const timeSlotNeeded = computed(() => {
            if (parcelShopBooking.value) {
                return false
            }
            return true
        })

        watch(
            () => settings,
            () => {
                const [error] = validate(settings.value, OrderSettings)

                validatedSettings.value.valid = error === undefined
                validatedSettings.value.errors = error
                    ? error.failures()
                    : false

                isSettingsLocationValid.value =
                    validatedSettings.value.valid ||
                    !_find(validatedSettings.value.errors, (item) =>
                        ['zipCode', 'city'].includes(item.key),
                    )

                if (settings.value.zipCode !== lastZipCode) {
                    isLocationFetched.value = false
                }

                lastZipCode = settings.value.zipCode
            },
            { deep: true, immediate: true },
        )

        watch(
            () => data,
            () => {
                console.info(
                    'data changed',
                    JSON.stringify(data.value.pickupAddress),
                )
            },
        )

        function mergeSettings(newSettings) {
            const [error] = validate(newSettings, OrderSettingsPartial)
            if (error) {
                throw error
            }

            _assign(settings.value, newSettings)
        }

        function addCampaign(campaignName, campaignId) {
            if (campaignName && campaignId) {
                data.value.campaign.name = campaignName
                data.value.campaign.id = campaignId
            }
        }

        function addProduct(product, amount = 1) {
            product = unref(product)
            log('add product', product)

            if (!data.value.products) {
                data.value.products = []
            }

            const index = _findIndex(data.value.products, { id: product.id })
            if (index > -1) {
                // set/increase amount
                if (product.freePickup) {
                    data.value.products[index].amount = amount
                } else {
                    data.value.products[index].amount += amount
                }
            } else {
                data.value.products.push({
                    ...product,
                    amount,
                })
            }
        }

        function removeProduct(product) {
            log('remove product', product?.name)

            const index = _findIndex(data.value.products, { id: product.id })
            if (index > -1) {
                data.value.products.splice(index, 1)
            }
        }

        function clearProducts() {
            log('clear products')
            data.value.products = []
        }

        function fetchLocation() {
            return $fetch('/order/zipcheck', {
                baseURL,
                method: 'POST',
                body: {
                    zipcode: settings.value.zipCode,
                },
                retry: 2,
            })
                .then((response) => {
                    let supported = false

                    if (response) {
                        if (
                            settings.value.b2bCustomerType ===
                                Enums.b2bCustomerType.industrial &&
                            !response.isParcelShippingCarrier
                        ) {
                            throw new Error('location not supported')
                        }
                        // if the zipcode is not supported by DHL, we return false
                        // otherwise we check for booking limits based on twosecondvintage quantities (see core)
                        supported = response.temporaryBookingLimits.includes(
                            false,
                        )
                            ? response.zipcodeIsSupported
                            : false

                        zipcodeIsInAtLeastOneBasearea.value =
                            response.zipcodeIsInAtLeastOneBasearea
                        zipcodeIsSupported.value = response.zipcodeIsSupported

                        if (response.basearea) {
                            baseareaName.value = response.basearea.name
                            baseareaCode.value = response.basearea.code
                        } else {
                            baseareaName.value = ''
                            baseareaCode.value = ''
                        }

                        productTypes.value = response.productTypes.map(
                            (productType) => productType.name,
                        )
                        supportedProducts.value = response.products

                        isParcelShippingCarrier.value =
                            response.isParcelShippingCarrier

                        applySettingsToOrder()
                    }

                    isLocationFetched.value = true
                    isLocationSupported.value = supported
                    isCurrentlyBookable.value = supported

                    return {
                        isLocationSupported: supported,
                        productTypes: productTypes.value,
                    }
                })
                .catch((error) => {
                    isLocationSupported.value = false
                    isLocationFetched.value = false
                    throw error
                })
        }

        async function validateLocation() {
            addressValidity.value = { validity: false }
            await $fetch('/order/validateLocation', {
                baseURL,
                method: 'POST',
                body: this.data.pickupAddress,
            })
                .then((response) => {
                    addressValidity.value = response
                })
                .catch(() => {
                    addressValidity.value = {
                        validity: false,
                        mismatchedFields: [],
                    }
                })
            return addressValidity.value
        }

        function save(_finalize = false) {
            log('save order')

            const orderData = collectOrderData()

            return $fetch('/order/submit', {
                baseURL,
                method: 'POST',
                body: {
                    ...orderData,
                },
                credentials: 'include',
            }).catch((error) => {
                Bugsnag.notify(error, (event) => {
                    event.context = 'order-store.save'
                })
                throw error
            })
        }

        function collectOrderData() {
            return {
                selectedProducts: data.value.products.map((item) => ({
                    id: item.id,
                    name: item.name,
                    amount: item.amount,
                    productType: item.productType.name,
                })),
                username: data.value.contact?.email,
                password: data.value.account?.password,
                email: data.value.contact?.email,
                accountType: data.value.accountType,
                pickupDate: data.value.pickupDate?.value,
                pickupTimeSlot: data.value.pickupTimeSlot?.value,
                pickupAddress: {
                    ...serverTools.convertClientAddress(
                        data.value.pickupAddress,
                    ),
                    ...data.value.contact,
                },
                invoiceAddress: data.value.separateInvoiceAddress
                    ? {
                          ...serverTools.convertClientAddress(
                              data.value.invoiceAddress,
                          ),
                          ...data.value.contact,
                      }
                    : {
                          ...serverTools.convertClientAddress(
                              data.value.pickupAddress,
                          ),
                          ...data.value.contact,
                      },
                campaign: {
                    name: data.value.campaign.name,
                    id: data.value.campaign.id,
                },
                newsletter: data.value.approvals.newsletter,
                dataProtection: data.value.approvals.dataProtection,
                customerType:
                    settings.value.customerGroup === 'private'
                        ? 'private'
                        : 'corporate',
                parcelPickup: isParcelShippingCarrier.value,
                parcelOption: isParcelShippingCarrier.value
                    ? data.value.parcelOption
                    : undefined,
                bookingPartner: settings.value.partner,
                fromUrl: window.location.href,
                b2bCustomerType: settings.value.b2bCustomerType,
            }
        }

        function applySettingsToOrder() {
            if (
                settings.value.zipCode &&
                data.value.pickupAddress.zipCode !== settings.value.zipCode
            ) {
                log('apply settings to order')
                data.value.pickupAddress.street = ''
                data.value.pickupAddress.streetNumber = ''
                data.value.pickupAddress.floor = ''
                data.value.pickupAddress.zipCode = settings.value.zipCode
                data.value.pickupAddress.city = settings.value.city
            }
        }

        function reset(assignUserData = true) {
            log('reset')
            data.value = _cloneDeep(dataDefaults)
            orderEdited.value = false

            if (assignUserData && userStore.initialized) {
                assignUserDataToSettings()
                assignUserDataToOrder()
            }
        }

        function resetSettings() {
            settings.value = {
                zipCode: '',
                city: '',
                customerGroup: Enums.customerGroups.private,
                tourType: Enums.tourTypes['2ndhandgoods'],
                partner: Enums.partners.recyclehero,
                b2bCustomerType: Enums.b2bCustomerType.general,
            }
        }

        function assignUserDataToSettings() {
            if (!userStore.authenticated) {
                return
            }

            log('assign user data to settings')
            settings.value.zipCode = userStore.data.invoiceAddress.zipCode
            settings.value.city = userStore.data.invoiceAddress.city
            settings.value.customerGroup = userStore.data.customerGroup
            // settings.value.tourType = ''
        }

        function assignUserDataToOrder() {
            if (!userStore.authenticated) {
                return
            }

            log('assign user data to order')

            const differentAddresses = !data.value.pickupAddress.zipCode
                ? false
                : data.value.pickupAddress.zipCode !==
                  userStore.data.invoiceAddress.zipCode

            if (!data.value.pickupAddress.zipCode) {
                _assign(data.value.pickupAddress, userStore.data.invoiceAddress)
            } else if (differentAddresses) {
                _assign(
                    data.value.pickupAddress,
                    _pick(userStore.data.invoiceAddress, [
                        'firstName',
                        'lastName',
                    ]),
                )
            } else {
                _assign(
                    data.value.pickupAddress,
                    _pick(userStore.data.invoiceAddress, [
                        'firstName',
                        'lastName',
                        'street',
                        'streetNumber',
                        'floor',
                    ]),
                )
            }

            data.value.separateInvoiceAddress =
                data.value.separateInvoiceAddress || differentAddresses

            _assign(data.value.invoiceAddress, userStore.data.invoiceAddress)

            _assign(data.value.contact, userStore.data.contact)
        }

        function assignContractDataToOrder(contract) {
            log('assign contract data to order')
            reset(false)

            _assign(data.value.pickupAddress, contract.pickupAddress)
            _assign(data.value.contact, contract.contact)

            settings.value.zipCode = contract.pickupAddress.zipCode
            settings.value.city = contract.pickupAddress.city
            settings.value.customerGroup = userStore.data.customerGroup
            settings.value.tourType = contract.tourType
        }

        let authenticatedDeferred = false
        nextTick(() => {
            watch(
                () => userStore.authenticated,
                (value) => {
                    if (value) {
                        authenticatedDeferred = true

                        userStore.initialize().then(() => {
                            if (!isOrderArea.value) {
                                assignUserDataToSettings()
                            }

                            assignUserDataToOrder()
                        })
                    } else {
                        if (authenticatedDeferred) {
                            reset()
                        }
                        authenticatedDeferred = false
                    }
                },
                { immediate: true },
            )

            if (orderEdited.value) {
                log('order was edited')
            }

            watch(
                () =>
                    settings.value.customerGroup +
                    ':' +
                    settings.value.tourType,
                () => {
                    clearProducts()
                },
            )
        })

        return {
            // data
            version,
            settings,
            addressValidity,
            zipcodeIsInAtLeastOneBasearea,
            zipcodeIsSupported,
            baseareaName,
            baseareaCode,
            isParcelShippingCarrier,
            data,
            productTypes,
            supportedProducts,

            // computed/generated
            changedSettings,
            validatedSettings,
            isSettingsLocationValid,
            isLocationFetched,
            isLocationSupported,
            calculatedPrice,
            orderEdited,
            isOrderArea,
            parcelShopBooking,
            timeSlotNeeded,

            // actions
            mergeSettings,
            addProduct,
            removeProduct,
            clearProducts,
            fetchLocation,
            validateLocation,
            fetch,
            save,
            reset,
            resetSettings,
            assignContractDataToOrder,
            addCampaign,
        }
    },
    {
        persist: {
            beforeRestore: (ctx) => {
                const key = ctx.store.$id
                const config = useRuntimeConfig()

                try {
                    const persistedData = JSON.parse(localStorage.getItem(key))
                    if (
                        persistedData &&
                        persistedData.version !== config.public.version
                    ) {
                        // prevent restore
                        console.info('version mismatch, clearing order store')
                        localStorage.setItem(
                            key,
                            _omit(persistedData, ['data']),
                        )
                    }
                } catch (error) {
                    console.warn('order store version check failed', error)

                    Bugsnag.notify(error, (event) => {
                        event.context = 'order-store.restore'
                    })
                }
            },
            paths: ['version', 'settings', 'data'],
        },
    },
)

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useOrder, import.meta.hot))
}
export { useOrder as default }
