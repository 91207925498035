export const Enums = {
    customerGroups: {
        private: 'private',
        corporate: 'corporate',
    },

    b2bCustomerType: {
        general: 'general',
        industrial: 'industrial',
    },

    productTypes: {
        recyclables: 'recyclables',
        '2ndhandgoods': '2ndhandgoods',
        clothesCampaigns: 'clothes-campaings',
    },

    tourTypes: {
        recyclables: 'recyclables',
        '2ndhandgoods': '2ndhandgoods',
        'clothes-campaigns': 'clothes-campaigns',
        byRecycleables: {
            recyclables: ['glas', 'paper', 'deposit'],
            '2ndhandgoods': ['clothes', 'handys', 'electronics'],
        },
    },

    orderTypes: {
        once: 'once',
        regularly: 'regularly', // not bookable anymore, only needed for checking legacy subscription contracts in "Kundenbereich"
    },

    frequencies: {
        '2x/1w': '2x/1w',
        '1w': '1w',
        '2w': '2w',
        '4w': '4w',
        '6w': '6w',
    },

    orderMinimumTerms: {
        '1m': '1m',
        '12m': '12m',
    },

    recyclables: {
        glas: 'glas',
        paper: 'paper',
        clothes: 'clothes',
        '-': '-',
        dropoff: 'dropoff',
        unload: 'unload',
        deposit: 'deposit',
        handys: 'handys',
        electronics: 'electronics',
        industrials: 'industrials',
    },

    recyclableGroups: {
        glas: 'recyclables',
        paper: 'recyclables',
        clothes: 'clothes',
        '-': '-',
        dropoff: 'dropoff',
        unload: 'unload',
        deposit: 'deposit',
        handys: 'handys',
        electronics: 'electronics',
        industrials: 'industrials',
    },

    recyclablesLabels: {
        glas: 'Altglas',
        paper: 'Altpapier',
        handys: 'Handys',
        electronics: 'Elektrogeräte',
        clothes: 'Altkleider',
        deposit: 'Pfand',
        'clothes-campaigns': 'Altkleider (nur für Gewerbekund*innen)',
    },

    transportContainers: {
        bag: 'bag',
        sack: 'sack',
        box: 'box',
        '-': '-',
    },

    parcelOptions: {
        pickup: 'pickup',
        parcelShop: 'parcelShop',
    },

    partners: {
        recyclehero: 'recyclehero',
        tobytiger: 'tobytiger',
        chapati: 'chapati',
    },
}

export class EnumsLegacy {
    static pickupStatus = {
        0: 'planned',
        1: 'paused',
        2: 'success',
        3: 'failure',
        4: 'canceled',
        5: 'success-done',
        6: 'failure-done',
    }

    static pickupStatusR = {
        planned: 0,
        paused: 1,
        success: 2,
        failure: 3,
        canceled: 4,
        'success-done': 5,
        'failure-done': 6,
    }

    static productSelectable = {
        0: 'Nein',
        1: 'Produkt',
        2: 'Abholgebühr',
        3: 'Zusatzgebühr',
        4: 'Home',
        5: 'Professional',
    }

    static recyclables = {
        1: 'glas',
        2: 'paper',
        3: 'clothes',
        4: '-',
        5: 'dropoff',
        6: 'unload',
        7: 'deposit',
        8: 'handys',
        9: 'electronics',
        10: 'industrials',
    }

    static transportContainer = {
        1: 'bag',
        2: 'sack',
        3: 'box',
        4: '-',
    }

    static segments = {
        1: 'private',
        2: 'restaurant',
        3: 'shop',
        4: 'office',
        5: 'hidden',
        6: 'other',
        7: 'dropoff/unload',
        8: 'i-home', // sub vat
        9: 'i-professional', // add vat
    }

    static segmentsText = {
        1: 'Home',
        2: 'Restaurant',
        3: 'Shop',
        4: 'Office',
        5: '-',
        6: 'Sonstiges',
    }

    static frequencies = {
        1: '2x week',
        2: 'weekly',
        5: 'every 2 weeks',
        6: 'every 4 weeks',
        7: 'every 6 weeks',
        _mapping: {
            1: Enums.frequencies['2x/1w'],
            2: Enums.frequencies['1w'],
            5: Enums.frequencies['2w'],
            6: Enums.frequencies['4w'],
            7: Enums.frequencies['6w'],
        },
    }

    static frequenciesToWeeks = {
        1: 1,
        2: 1,
        5: 2,
        6: 4,
        7: 6,
    }

    static freqToNumOfDays = {
        // FIXME should it not be fractions for 5 6 and 7 ?
        1: 2,
        2: 1,
        5: 1,
        6: 1,
        7: 1,
    }

    // if we do multiple times a week its still 4.3 weeks in a month :)
    static freqToPriceFactor = {
        1: 4.3,
        2: 4.3,
        5: 2.15,
        6: 1.075,
        7: 0.72,
    }

    static frequenciesText = {
        1: '2x pro Woche',
        2: '1x pro Woche',
        5: '1x alle 2 Wochen',
        6: '1x alle 4 Wochen',
        7: '1x alle 6 Wochen',
    }

    static fee2Name = {
        0: 'Kostenlose Abholung',
        1: 'On Demand Gebühr',
        2: 'Abo Abholgebühr',
        3: 'Abo Abholgebühr selten',
    }

    static tourTypes = {
        1: 'Normal',
        2: '2HandGoods',
        3: 'Altkleider-Kampagne',
        _mapping: {
            1: Enums.tourTypes.recyclables,
            2: Enums.tourTypes['2ndhandgoods'],
            3: Enums.tourTypes['clothes-campaigns'],
        },
    }

    static statusActiveInactive = {
        0: 'inactive',
        1: 'active',
    }

    static customerStatus = {
        0: 'created',
        1: 'onboarded',
    }

    static statusContract = {
        0: 'new (payment not set)',
        1: 'payment requested',
        2: 'paid',
        3: 'complete',
    }

    static statusCustomer = {
        0: 'new (payment not set)',
        1: 'new (onboarding complete)',
        2: 'active',
        3: 'inactive',
    }

    static trueFalse = {
        0: 'false',
        1: 'true',
    }

    static weekdayGerman = {
        0: 'Sonntag',
        1: 'Montag',
        2: 'Dienstag',
        3: 'Mittwoch',
        4: 'Donnerstag',
        5: 'Freitag',
        6: 'Samstag',
    }

    static weekday = {
        0: 'sun',
        1: 'mon',
        2: 'tue',
        3: 'wed',
        4: 'thu',
        5: 'fri',
        6: 'sat',
    }

    static payactiveStates = {
        CREATING: 0,
        PENDING: 1,
        COMPLETED: 2,
        VERIFIED: 3,
        ABORTED: 4,
        ERROR: 5,
        CANCELLED: 6,
        MANUAL: 7,
    }

    static ValidationTextGerman = {
        salutation: 'Anrede',
        firstName: 'Vorname',
        lastName: 'Nachname',
        email: 'E-Mail',
        phone: 'Telefon',
        street: 'Straße',
        streetNumber: 'Hausnummer',
        city: 'Stadt',
    }

    static customerTypes = {
        1: 'Home',
        2: 'Professional',
        _mapping: {
            1: Enums.customerGroups.private,
            2: Enums.customerGroups.corporate,
        },
    }

    static customerGroupToType = {
        [Enums.customerGroups.private]: { index: 1, name: 'Home' },
        [Enums.customerGroups.corporate]: { index: 2, name: 'Professional' },
    }
}
