<template>
    <nuxt-link :class="`${linkClass} ${darkmode}`" :target="linkTarget"
        ><slot
    /></nuxt-link>
</template>

<script>
export default {
    props: {
        textLink: {
            type: Boolean,
            default: false,
        },
        dark: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        linkClass() {
            return this.textLink ? 'text-link' : 'linky'
        },

        darkmode() {
            return this.dark ? 'dark' : ''
        },

        isExternal() {
            let value = false
            if (
                typeof this.$attrs.to === 'string' &&
                /^https?:\/\//.test(this.$attrs.to)
            ) {
                value = true
            }
            return value
        },

        linkTarget() {
            if (this.$attrs.target) {
                return this.$attrs.target
            }

            return this.isExternal ? '_blank' : ''
        },
    },
}
</script>

<style lang="scss" scoped>
.linky {
    position: relative;
    cursor: pointer;
    user-select: none;
    transition: color 0.3s;
    border-bottom: 2px solid transparent;

    &:not(.btn) {
        color: theme('colors.primary');

        &::before {
            content: '';
            position: absolute;
            left: 0%;
            bottom: -2px;
            width: 100%;
            height: 2px;
            background-color: currentColor;
            opacity: 1;
            transform: scalex(0);
            transform-origin: bottom right;
            transition: transform 0.3s
                theme('transitionTimingFunction.easeOutCubic');
        }

        &.active,
        &.router-link-exact-active:not(.ignore-router-link-active),
        &:hover {
            color: theme('colors.primary-dark');

            &::before {
                background-color: currentColor;
                opacity: 1;
                transform: scalex(1);
                transform-origin: bottom left;
                transition: transform 0.3s
                    theme('transitionTimingFunction.easeOutCubic');
            }
        }
    }

    &.white {
        color: #fff;

        &.active,
        &.router-link-exact-active:not(.ignore-router-link-active),
        &:hover {
            color: #fff;
        }
    }

    &.dark {
        color: #333c4d;

        &.active,
        &.router-link-exact-active:not(.ignore-router-link-active),
        &:hover {
            color: #333c4d;
        }
    }
}
</style>
