<template>
    <span :class="['burger', state]" @animationend="handleAnimationEnd"
        ><svg
            xmlns="http://www.w3.org/2000/svg"
            width="29"
            height="23"
            viewBox="0 0 29 23"
            role="img"
            aria-label="Navigation"
        >
            <g fill="none" stroke="#fff" stroke-width="3">
                <path class="p1" d="M1.5 1.5h26" />
                <path class="p2" d="M1.5 11.5h26" />
                <path class="p3" d="M1.5 21.5h26" />
            </g>
        </svg>
    </span>
</template>

<script>
export default {
    props: {
        active: Boolean,
    },

    data() {
        return {
            state: '',
        }
    },

    watch: {
        active(value) {
            if (value) {
                this.state = 'show-close'
            } else {
                this.state = 'hide-close'
            }
        },
    },

    methods: {
        handleAnimationEnd() {
            if (this.state === 'show-close') {
                this.state = 'close'
            } else if (this.state === 'hide-close') {
                this.state = ''
            }
        },
    },
}
</script>

<style scoped lang="scss">
// @import '/src/assets/config';

.burger {
    cursor: pointer;

    &:deep(svg) {
        transform-origin: center center;

        path {
            transform-origin: center center;
        }
    }

    &.show-close {
        &:deep(svg) {
            path {
                animation-duration: 0.4s;
                animation-timing-function: theme(
                    'transitionTimingFunction.easeOutCubic'
                );
            }

            .p1 {
                animation-name: top-bar;
            }

            .p2 {
                animation-name: middle-bar;
            }

            .p3 {
                animation-name: bottom-bar;
            }
        }
    }

    &.hide-close {
        &:deep(svg) {
            path {
                animation-duration: 0.4s;
                animation-timing-function: theme(
                    'transitionTimingFunction.easeInCubic'
                );
                animation-fill-mode: forwards;
                animation-direction: alternate-reverse;
            }

            .p1 {
                animation-name: top-bar;
            }

            .p2 {
                animation-name: middle-bar;
            }

            .p3 {
                animation-name: bottom-bar;
            }
        }
    }

    &.close {
        &:deep(svg) {
            .p1 {
                transform: rotate(45deg) translate(0, 43%);
            }

            .p2 {
                opacity: 0;
            }

            .p3 {
                transform: rotate(-45deg) translate(0, -43%);
            }
        }
    }
}

@keyframes top-bar {
    0% {
        transform: rotate(0deg) translate(0, 0%);
    }
    50% {
        transform: rotate(0deg) translate(0, 43%);
    }
    100% {
        transform: rotate(45deg) translate(0, 43%);
    }
}

@keyframes middle-bar {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

@keyframes bottom-bar {
    0% {
        transform: rotate(0deg) translate(0, 0%);
    }
    50% {
        transform: rotate(0deg) translate(0, -43%);
    }
    100% {
        transform: rotate(-45deg) translate(0, -43%);
    }
}
</style>
