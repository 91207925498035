import _defaults from 'lodash/defaults.js'
import _cloneDeep from 'lodash/cloneDeep.js'
import _map from 'lodash/map.js'
import _each from 'lodash/each.js'
import _isArray from 'lodash/isArray.js'
import PubSub from 'pubsub-js'

const plugins = [
    import('./plugins/plausible').then((m) => m.default),
    import('./plugins/google-tag-manager').then((m) => m.default),
    import('./plugins/facebook').then((m) => m.default),
]

function Tracker() {
    const config = useRuntimeConfig()
    const nuxtApp = useNuxtApp()

    const self = this
    self.nuxtApp = nuxtApp

    if (config.public.env === 'production') {
        self.debug = {
            info: () => {},
            log: () => {},
            warn: () => {},
            error: () => {},
        }
    } else {
        self.debug = console
    }

    self.started = false

    self.consentLevel = {
        necessary: false,
        functionality: false,
        tracking: false,
        targeting: false,
    }

    self.emitBuffer = []

    self.scripts = []
    // {
    //     name: '',
    //     loaded: false,
    //     level: '',
    //     settings: {
    //         src: '',
    //         defer: true,
    //         async: true,
    //         attrs: {},
    //     },
    //     callback: () => {}
    // }

    self.scrolled = {}

    self.nuxtApp.$router.afterEach((to, from) => {
        if (from.path !== to.path) {
            self.emit('page', {
                path: to.path,
                params: to.params,
                query: to.query,
            })
        }
    })
}

Tracker.prototype.start = function start(consentLevel) {
    const self = this

    if (process.server) {
        return false
    }

    if (self.started) {
        return false
    }

    Object.assign(self.consentLevel, consentLevel)

    self.started = true

    self.load().then(() => {
        if (self.emitBuffer.length) {
            _each(self.emitBuffer, (call) =>
                self.emit(call.topic, call.data, true),
            )
        }
    })
}

Tracker.prototype.load = function load() {
    const self = this

    if (!self.started) {
        return Promise.reject(new Error('tracker not started yet'))
    }

    return Promise.all(
        _map(self.scripts, (script) => {
            if (!script || script.loaded) {
                return false
            }

            if (!self.consentLevel[script.level]) {
                self.debug.log('tracker:', script.name, 'no consent')
                return false
            }

            script.loaded = true
            self.debug.log('tracker:', script.name, 'loaded')

            if (script.settings) {
                let settingsList = script.settings
                if (!_isArray(settingsList)) {
                    settingsList = [settingsList]
                }

                return Promise.all(
                    _map(settingsList, (settings) =>
                        self.appendScript(settings),
                    ),
                ).then(script.callback)
            } else {
                return Promise.resolve(script.callback())
            }
        }),
    )
}

Tracker.prototype.emitScroll = function emitScroll(section) {
    const self = this
    const path = self.nuxtApp.$router.currentRoute.value.path

    if (!self.scrolled[path]) {
        self.scrolled[path] = []
    }

    if (!self.scrolled[path].includes(section)) {
        self.emit('scroll', { path, section })
        self.scrolled[path].push(section)
    }
}

Tracker.prototype.emit = function emit(topic, data, buffered = false) {
    const self = this

    if (process.server) {
        return
    }

    data = _cloneDeep(data)

    if (!self.started) {
        self.emitBuffer.push({ topic, data })
    }

    // publish events even when not started for other scripts
    self.debug.log('tracker:', topic, data, buffered)
    PubSub.publish(topic, { ...data, buffered })
}

Tracker.prototype.listen = function listen(topic, callback, skipBuffered) {
    PubSub.subscribe(topic, (event, data) => {
        if (data && data.buffered && skipBuffered) {
            return
        }

        delete data.buffered
        callback(event, data)
    })
}

Tracker.prototype.add = function add(data) {
    const self = this
    self.scripts.push(_cloneDeep(data))

    if (self.started) {
        self.load()
    }
}

Tracker.prototype.createScriptTag = function createScriptTag(settings) {
    const script = document.createElement('script')
    script.type = 'text/javascript'

    if (settings.src) {
        script.setAttribute('src', settings.src)
    } else if (settings.code) {
        script.innerHTML = settings.code
    }

    if (settings.defer) {
        script.setAttribute('defer', settings.defer)
    }

    if (settings.async) {
        script.setAttribute('async', settings.async)
    }

    if (settings.attrs) {
        _each(settings.attrs, (value, key) => {
            script.setAttribute(key, value)
        })
    }

    return script
}

Tracker.prototype.appendScript = function appendScript(settings) {
    const self = this
    if (settings.src) {
        _defaults(settings, { defer: true, async: true })
    }

    return new Promise((resolve) => {
        const scriptTag = self.createScriptTag(settings)

        if (settings.src) {
            scriptTag.onload = resolve
            document.head.appendChild(scriptTag)
        } else if (settings.code) {
            document.head.appendChild(scriptTag)
            setTimeout(resolve, 1)
        }
    })
}

export default defineNuxtPlugin((nuxtApp) => {
    const instance = new Tracker(nuxtApp)
    nuxtApp.provide('tracker', instance)

    if (!process.server) {
        _each(plugins, (plugin) => {
            plugin.then((m) => m(instance, nuxtApp))
        })
    }
})
