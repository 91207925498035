const defs = {
    perMonth: ' / Monat',

    customerGroup: {
        private: 'Privat',
        corporate: 'Gewerbe',
    },

    routes: {
        index: 'Startseite',
        'old-clothes': 'Altkleider',
        'old-clothes-berlin': 'Berlin',
        'old-clothes-frankfurt': 'Frankfurt',
        'old-clothes-hamburg': 'Hamburg',
        // 'old-clothes-cologne': 'Köln',
        // 'old-clothes-munich': 'München',
        handys: 'Handys',
        electronics: 'Elektrogeräte',
        faq: 'FAQ',
        team: 'Team',
        jobs: 'Jobs',
        blog: 'Blog',
        'social-projects': 'Soziales',
        contact: 'Kontakt',
        legal: 'Impressum',
        privacy: 'Datenschutz',
        terms: 'AGB',
        order: 'Bestellen',
        'order-confirmation': 'Bestellbestätigung',
        'order-thank-you': 'Vielen Dank für Deine Bestellung',
        login: 'Anmelden',
        register: 'Registrieren',
        'reset-password': 'Passwort zurücksetzen',
        'user-dashboard': 'Übersicht',
        'user-data': 'Meine Daten',
        'user-invoices': 'Meine Rechnungen',
    },

    settings: {
        tourType: {
            recyclables: 'Wertstoffe',
            clothes: 'Altkleider',
            '2ndhandgoods': '2nd Hand Goods',
        },
    },

    transportContainer: {
        box: {
            name: 'Box',
            descriptions: {
                recyclables: `Eine Box Altglas entspricht ca. 45 Weinflaschen.
                Bei Altpapier entspricht eine Box einem kleinen Umzugskarton.`,
                'clothes-campaigns': `In eine Box passen ca. 8 Kilogramm. Das 
                entspricht ungefähr einem großen gefüllten Müllsack oder einer 
                großen gefüllten blauen Tragetasche eines schwedischen Möbelhauses.`,
            },
        },
        bag: {
            name: 'Bag',
            descriptions: {
                recyclables: `Eine Bag entspricht einem maximal 70 cm hohen Stapel 
                Altpapier mit den Maßen 70x70 cm.`,
                'clothes-campaigns': `In eine Bag passen ca. 30 Kilogramm Altkleider. 
                Also ungefähr vier große gefüllte Müllsacke oder blaue Tragetasche 
                eines schwedischen Möbelhauses.`,
            },
        },
    },

    weekdaysByIndex: {
        1: 'Montag',
        2: 'Dienstag',
        3: 'Mittwoch',
        4: 'Donnerstag',
        5: 'Freitag',
    },

    pickupStatus: {
        planned: 'geplant',
        paused: 'pausiert',
        success: 'abgeholt',
        failure: 'Fehler bei Abholung',
        canceled: 'ausgesetzt',
        'success-done': 'abgeholt & geprüft',
        'failure-done': 'Fehler bei Abholung',
    },

    dialogs: {
        generalError: {
            title: 'Leider ist ein Fehler aufgetreten 😲!',
            message: `Wir wurden über den Fehler informiert und werden ihn schnellstmöglich beheben.`,
        },
    },

    order: {
        dialogs: {
            notOrderable: {
                title: 'Nicht im Liefergebiet',
                message: `Du kannst Dich gerne umschauen, aber leider keine Abholung in Auftrag geben, weil unser Liefergebiet noch nicht Deine PLZ umfasst.`,
            },
        },

        type: {
            once: 'Einmalig',
            regularly: 'Regelmäßig',
        },

        frequency: {
            // '3x/1w': '3x pro Woche',
            '2x/1w': '2x pro Woche',
            '1w': '1x pro Woche',
            '2w': 'alle 2 Wochen',
            '4w': 'alle 4 Wochen',
            // '6w': 'alle 6 Wochen',
        },

        minimumTerms: {
            '1m': '1 Monat',
            '12m': '12 Monate',
        },

        minimumTermsDescriptive: {
            '1m': 'monatlich kündbar',
            '12m': '12 Monate',
        },

        timeslots: {
            '9-12': '9:00 - 12:00',
            '11-15': '11:00 - 15:00',
            '14-18': '14:00 - 18:00',
            '17-21': '17:00 - 21:00',
        },

        taxNote: (taxRate) => ({
            included: `Alle Preise inklusive ${taxRate}% Mehrwertsteuer`,
            excluded: `Alle Preise zuzüglich ${taxRate}% Mehrwertsteuer`,
        }),
    },

    login: {
        errorMessages: {
            'Unknown customer':
                'Die Zugangsdaten sind nicht korrekt. Bitte überprüfe sie, oder nutze die "Passwort vergessen?"-Funktion.',
            'Login failed':
                'Anmeldung fehlgeschlagen! Bitte überprüfe Deine Zugangsdaten.',
        },
    },

    register: {
        errorMessages: {},
    },

    resetPassword: {
        errorMessages: {
            'unknown email': 'Diese E-Mail konnten wir nicht finden.',
            'unknown customer': 'Das Kundenkonto existiert nicht.',
            'code expired':
                'Dieser Sicherheits-Code scheint abgelaufen zu sein. Bitte fordere eine neue E-Mail zum Zurücksetzen des Passworts an.',
        },
    },

    userData: {
        errorMessages: {},
    },

    invoiceStatus: {
        DRAFT: 'Entwurf',
        OPEN: 'Ausstehend',
        PAID: 'Bezahlt',
        CANCELLED: 'Abgebrochen',
        CONFLICT: 'Fehler',

        // CREATING: 'Erstellt',
        // PENDING: 'Offen',
        // COMPLETED: 'Abgeschlossen',
        // VERIFIED: 'Bestätigt',
        // ABORTED: 'Abgebrochen',
        // ERROR: 'Fehler',
        // CANCELLED: 'Widerrufen',
        // MANUAL: 'Manuell',
    },
}

export function useI18n() {
    return defs
}
