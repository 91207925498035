export function useLog(prefix = '') {
    const logStartTime = new Date().getTime()

    function log() {
        const time = (new Date() - logStartTime) / 1000
        console.log(prefix + ':', ...arguments, time)
    }

    return log
}
