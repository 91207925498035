<template>
    <transition
        name="quick-fade"
        :class="{ scroll: scrollable }"
        @after-leave="afterHide"
    >
        <div
            v-show="stateWrapper"
            :class="['simple-layer', { loading: layerLoading }]"
            @mousedown.self="handleBackgroundDown"
            @mouseup.self="handleBackgroundUp"
            @touchdown.self="handleBackgroundDown"
            @touchup.self="handleBackgroundUp"
        >
            <transition name="zoom-fade" @after-enter="contentVisible = true">
                <div
                    v-show="stateContent"
                    :class="['layer-content', { dialog }]"
                >
                    <icon
                        v-if="closeMethods.includes('button') && !closable"
                        name="x"
                        class="close"
                        @click.prevent.stop="(ev) => hide()"
                    />

                    <div
                        ref="inner"
                        class="inner"
                        @click="clickProgress = false"
                    >
                        <slot v-if="$slots.default" />
                        <component
                            :is="processedContentData.component"
                            v-else
                            v-bind="processedContentData.params"
                            :is-visible="contentVisible"
                            @vue:mounted="contentReady"
                            @result="
                                (data) =>
                                    processedContentData.handleResult(data)
                            "
                            @close="hide"
                        ></component>
                    </div>
                </div>
            </transition>
        </div>
    </transition>
</template>

<script>
import { markRaw } from 'vue'

export default {
    inject: ['loading'],

    props: {
        state: {
            type: Boolean,
            default: false,
        },

        contentData: {
            type: [Promise, Object, Array],
            default: () => ({}),
        },

        dialog: {
            type: Boolean,
            default: false,
        },

        scrollable: {
            type: Boolean,
            default: true,
        },

        closeMethods: {
            type: Array,
            default: () => ['background', 'button'],
        },

        closable: {
            type: Boolean,
            default: false,
        },
    },

    emits: ['update:state'],

    async setup() {
        const [log] = await Promise.all([useLog('simple-layer')])

        return {
            log,
        }
    },

    data() {
        return {
            layerLoading: false,

            stateWrapper: false,
            stateContent: false,
            processedContentData: {
                component: false,
                params: {},
                handleResult: () => {},
                afterHide: () => {},
            },
            contentVisible: false,

            internalState: 'init',
            closeReason: false,
            clickProgress: false,
        }
    },

    watch: {
        state(value, _oldValue) {
            this.log('state', value)

            if (value) {
                this.stateWrapper = true
                this.internalState = 'show'
                this.prepareContent(this.contentData)
            } else if (this.stateWrapper) {
                this.hide()
            }
        },

        // fix: loader pops
        // layerLoading(value) {
        //     this.loading(value)
        // },
    },

    methods: {
        show(data) {
            const vm = this

            vm.stateWrapper = true
            vm.internalState = 'show'

            if (data) {
                vm.prepareContent(data)
            } else {
                vm.contentReady()
                vm.processedContentData.handleResult = () => {}
                vm.processedContentData.afterHide = () => {}
            }
        },

        hide(reason) {
            const vm = this

            if (vm.internalState === 'hidden' || !vm.stateWrapper) {
                return Promise.resolve()
            }

            vm.stateWrapper = false
            vm.internalState = 'hide'
            vm.closeReason = reason

            return new Promise((resolve) => {
                const stopWatch = vm.$watch(
                    () => vm.internalState,
                    (value) => {
                        if (value === 'hidden') {
                            stopWatch()
                            resolve()
                        }
                    },
                )
            })
        },

        afterHide() {
            const vm = this
            vm.stateContent = false
            vm.contentVisible = false
            vm.processedContentData.afterHide(vm.closeReason)

            vm.processedContentData = {
                component: false,
                params: {},
                handleResult: () => {},
                afterHide: () => {},
            }

            vm.internalState = 'hidden'

            if (vm.state) {
                vm.$emit('update:state', false)
            }
        },

        contentReady() {
            const vm = this
            this.log('content ready')

            vm.$refs.inner.scrollTop = 0

            vm.layerLoading = false
            vm.stateContent = true

            vm.internalState = 'ready'
        },

        prepareContent(data) {
            const vm = this

            if (vm.layerLoading) {
                return
            }
            vm.layerLoading = true

            vm.$refs.inner.scrollTop = 0

            return Promise.resolve(data)
                .then((contentData) => {
                    vm.processedContentData.component = markRaw(
                        contentData.component,
                    )
                    vm.processedContentData.params = contentData.params

                    vm.processedContentData.handleResult =
                        contentData.handleResult || (() => {})

                    vm.processedContentData.afterHide =
                        contentData.afterHide || (() => {})
                })
                .catch((error) => {
                    vm.layerLoading = false
                    console.warn('layer', error)

                    this.$bugsnag.notify(error, (event) => {
                        event.context = 'simple-layer.prepare-content'
                    })

                    vm.hide()
                })
        },

        handleBackgroundDown() {
            if (!this.closeMethods.includes('background')) {
                return
            }

            this.clickProgress = true
        },

        handleBackgroundUp() {
            if (!this.closeMethods.includes('background')) {
                return
            }

            if (this.clickProgress) {
                this.hide()
            }

            this.clickProgress = false
        },
    },
}
</script>

<style scoped lang="scss">
// @use 'sass:math';
@import '~/assets/config';

.simple-layer {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    background-color: theme('colors.gray.600' / 75%);
    z-index: 4000;
    // padding-top: var(--nav-height);

    &.scroll {
        .layer-content {
            > .inner {
                height: auto;
                max-height: 100%;
                @include scroll-vertical-colored;
                @include scroll-border;
            }
        }
    }

    @screen sm {
        padding-right: var(--section-padding-x);
        padding-left: var(--section-padding-x);
    }
}

.layer-content {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    max-height: 90%;
    max-width: 90%;
    z-index: 10;

    &.dialog {
        width: auto;
        max-width: 56rem;
        box-shadow: 0 25px 50px -12px #00000040;

        > .inner {
            padding: 1.5rem;
            background-color: #fff;

            :deep(header) {
                padding-right: 1.5rem;
            }
        }

        > .close {
            right: 1rem;
            top: 1rem;
        }

        @screen sm {
            > .inner {
                :deep(header) {
                    padding-right: 2rem;
                }
            }

            > .close {
                right: 1rem;
                top: 1.25rem;
            }
        }

        @screen md {
            > .inner {
                :deep(header) {
                    padding-right: 2.5rem;
                }
            }

            > .close {
                right: 1rem;
                top: 1.5rem;
            }
        }
    }

    > .inner {
        display: block;
        flex: auto 0 1;
    }

    > :deep(.close) {
        position: absolute;
        z-index: 30;
        right: 0;
        top: 0;
        width: 2.5rem;
        height: 2.5rem;
        cursor: pointer;
        color: theme('colors.slate.900');
        transform: scale(1);
        transition: transform 0.25s
            theme('transitionTimingFunction.easeOutCubic');

        &:hover {
            transform: scale(1.1);
        }
    }
}
</style>
