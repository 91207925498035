import { defineStore } from 'pinia'
import _map from 'lodash/map.js'
import _pick from 'lodash/pick.js'
import _assign from 'lodash/assign.js'
import _cloneDeep from 'lodash/cloneDeep.js'
import Bugsnag from '@bugsnag/js'

export default defineStore('user', () => {
    const serverTools = useServerTools()
    const log = useLog('user-store')
    const config = useRuntimeConfig()
    const baseURL = config.public.sources.api.url
    const cookieAuthenticated = useCookie('authenticated')

    const authenticated = ref(!!cookieAuthenticated.value)

    const email = ref('')
    const customerId = ref('')

    const dataDefaults = {
        customerGroup: Enums.customerGroups.private,
        customerId: '',
        invoiceAddress: {
            firstName: '',
            lastName: '',
            organization: '',
            street: '',
            streetNumber: '',
            zipCode: '',
            city: '',
            additionalInfo: '',
        },

        contact: {
            email: '',
            phone: '',
        },
    }

    const data = ref(_cloneDeep(dataDefaults))

    const fullName = computed(() => {
        let name = data.value?.invoiceAddress?.lastName || ''
        if (data.value?.invoiceAddress?.firstName) {
            name = data.value.invoiceAddress.firstName + ' ' + name
        }

        return name
    })

    const paymentDefaults = {
        configured: null,
        url: '',
    }
    const payment = ref(_cloneDeep(paymentDefaults))

    const contracts = ref([])
    const invoices = ref([])
    const pickups = ref([])

    const initialized = ref(false)
    const fetchingAccountData = ref(false)

    let initializationPromise

    let authenticatedDeferred = false
    watch(
        () => authenticated.value,
        (value) => {
            if (value) {
                authenticatedDeferred = true
                initialize(true)
            } else {
                if (authenticatedDeferred) {
                    reset()
                }
                authenticatedDeferred = false
            }
        },
        { immediate: true },
    )

    function initialize(restart = false) {
        if (initializationPromise && !restart) {
            return initializationPromise
        }

        if (process.server) {
            log('prevent initialization')
            initializationPromise = new Promise((_resolve) => {})
            return initializationPromise
        }

        initializationPromise = new Promise((resolve) => {
            Promise.all([fetchAccountData()]).then(() => {
                initialized.value = true
                resolve()
            })
        })

        return initializationPromise
    }

    function reset() {
        log('reset')
        customerId.value = ''
        email.value = ''
        data.value = _cloneDeep(dataDefaults)
        payment.value = _cloneDeep(paymentDefaults)
        contracts.value = []
        invoices.value = []
        pickups.value = []
        initialized.value = false
        fetchingAccountData.value = false
    }

    function fetchContracts() {
        log('fetching contracts')

        const controller = new AbortController()
        setTimeout(() => controller.abort(), 20000)

        return $fetch
            .raw('/account/contracts', {
                baseURL,
                method: 'GET',
                redirect: 'error',
                credentials: 'include',
                signal: controller.signal,
            })
            .then((response) => {
                contracts.value = _map(response._data.contracts, (contract) =>
                    serverTools.convertServerContract(contract),
                )
            })
            .catch((error) => {
                log('error', error.data.message)

                Bugsnag.notify(error, (event) => {
                    event.context = 'user-store.fetch-contracts'
                })

                throw error
            })
    }

    function setReminder(contract, state) {
        log('set reminder', state)
        return $fetch
            .raw(
                `/account/contract/${contract.id}/setreminder/${state ? 1 : 0}`,
                {
                    baseURL,
                    method: 'GET',
                    redirect: 'error',
                    credentials: 'include',
                },
            )
            .then((response) => {
                if (response?.status === 200) {
                    contract.sendReminder = state
                    return true
                }

                return false
            })
            .catch((error) => {
                log('error', error.data.message)

                Bugsnag.notify(error, (event) => {
                    event.context = 'user-store.set-reminder'
                })

                return false
            })
    }

    function editContract(contract, newData) {
        log('edit contract')

        return new Promise((resolve) => {
            const serverAddress = serverTools.convertClientAddress(
                newData.pickupAddress,
            )
            const serverContact = serverTools.convertClientContact(
                newData.contact,
            )

            const serverData = {
                ..._pick(serverAddress, [
                    'firstName',
                    'lastName',
                    'additionalAdressInfo',
                ]),
                ...serverContact,
            }

            resolve(serverData)
        })
            .then((serverData) => {
                return $fetch.raw(`account/contract/${contract.id}/address`, {
                    baseURL,
                    method: 'POST',
                    redirect: 'error',
                    credentials: 'include',
                    body: serverData,
                })
            })
            .then((response) => {
                if (response?.status !== 200) {
                    throw new Error(response._data.message)
                }

                contract.pickupAddress.firstName =
                    newData.pickupAddress.firstName
                contract.pickupAddress.lastName = newData.pickupAddress.lastName
                contract.pickupAddress.additionalInfo =
                    newData.pickupAddress.additionalInfo

                contract.contact.email = newData.contact.email
                contract.contact.phone = newData.contact.phone
            })
    }

    function cancelContract(contract, cancelationDetails) {
        log('cancel contract')
        const { reason, cancelationNote } = cancelationDetails
        return $fetch
            .raw(`account/contract/${contract.id}/cancel`, {
                baseURL,
                method: 'POST',
                redirect: 'error',
                credentials: 'include',
                body: {
                    reason,
                    cancelationNote,
                },
            })
            .then((response) => {
                if (response?._data.status !== 'ok') {
                    throw new Error(response._data.message)
                }

                contract.cancellation.actualDate =
                    contract.cancellation.possibleDate
                contract.cancellation.possibleDate = undefined
                fetchPickups()
            })
    }

    function fetchInvoices() {
        log('fetching invoices')

        const controller = new AbortController()
        setTimeout(() => controller.abort(), 8000)

        return $fetch
            .raw('/account/invoices', {
                baseURL,
                method: 'GET',
                redirect: 'error',
                credentials: 'include',
                signal: controller.signal,
            })
            .then((response) => {
                invoices.value = _map(response._data.invoices, (raw) => {
                    const invoice = serverTools.convertServerInvoice(raw)
                    invoice.url = baseURL + '/account/invoice/' + invoice.id
                    return invoice
                })
            })
            .catch((error) => {
                log('error', error.data.message)

                Bugsnag.notify(error, (event) => {
                    event.context = 'user-store.fetch-invoices'
                })

                return false
            })
    }

    function fetchPickups() {
        log('fetching pickups')
        return $fetch
            .raw('/account/pickups', {
                baseURL,
                method: 'GET',
                redirect: 'error',
                credentials: 'include',
            })
            .then((response) => {
                pickups.value = _map(
                    response._data.pickups,
                    serverTools.convertServerPickup,
                )
            })
            .catch((error) => {
                log('error', error.data.message)

                Bugsnag.notify(error, (event) => {
                    event.context = 'user-store.fetch-pickups'
                })
                // redirected, not authenticated
                authenticated.value = false
                return false
            })
    }

    function fetchAccountData() {
        fetchingAccountData.value = true
        log('fetching account data')
        return $fetch
            .raw('/account/data', {
                baseURL,
                method: 'GET',
                redirect: 'error',
                credentials: 'include',
            })
            .then((response) => {
                fetchingAccountData.value = false
                processServerAccountData(response._data)
            })
            .catch((error) => {
                log('error', error)

                Bugsnag.notify(error, (event) => {
                    event.context = 'user-store.fetch-account-data'
                })
                // redirected, not authenticated
                authenticated.value = false
                return false
            })
    }

    function saveAccountData(updatedData) {
        log('saving account data')
        return $fetch
            .raw('/account/data', {
                baseURL,
                method: 'POST',
                redirect: 'error',
                credentials: 'include',
                body: serverTools.convertClientData(updatedData),
            })
            .then((response) => {
                processServerAccountData(response._data)
                return response._data
            })
            .catch((error) => {
                return error.data
            });
    }

    function processServerAccountData(rawData) {
        const serverData = serverTools.convertServerData(rawData)
        customerId.value = serverData.customerId
        email.value = serverData.email

        data.value.customerGroup = serverData.invoiceAddress.organization
            ? Enums.customerGroups.corporate
            : Enums.customerGroups.private

        _assign(data.value.invoiceAddress, serverData.invoiceAddress)
        _assign(data.value.contact, serverData.contact)
        _assign(data.value.customerId, serverData.customerId)

        payment.value.configured = !rawData.onboardingUrl
        payment.value.url = rawData.onboardingUrl
    }

    function checkAuthentication(options) {
        log('checking authentication')
        return $fetch
            .raw(
                '/auth/status',
                _assign(
                    {
                        baseURL,
                        retry: 0,
                        redirect: 'error',
                        credentials: 'include',
                    },
                    options,
                ),
            )
            .then((response) => {
                authenticated.value = response.status === 200
                return authenticated.value
            })
            .catch((error) => {
                Bugsnag.notify(error, (event) => {
                    event.context = 'user-store.check-authentication'
                })

                authenticated.value = false
                return false
            })
    }

    function login(email, password) {
        return $fetch
            .raw('/auth/login', {
                baseURL,
                method: 'POST',
                body: { username: email, password },
                credentials: 'include',
            })
            .then((response) => {
                authenticated.value = response.status === 200
                return response._data.userId
            })
            .catch((error) => {
                throw new Error(error.data.detail)
            })
    }

    function logout() {
        return $fetch
            .raw('/auth/logout', {
                baseURL,
                credentials: 'include',
                method: 'POST',
            })
            .then((response) => {
                authenticated.value = response.status !== 200
            })
            .catch((error) => {
                throw new Error(error.data.detail)
            })
    }

    function register(email, password, newsletter) {
        const currentUrl = window.location.href
        return $fetch
            .raw('/auth/signup', {
                baseURL,
                method: 'POST',
                body: { email, password, newsletter, fromUrl: currentUrl },
                credentials: 'include',
            })
            .then((response) => {
                if (response.status !== 200) {
                    throw new Error(response._data.message)
                }
            })
    }

    function resetPasswordSendEmail(email) {
        return $fetch
            .raw('/auth/send-password-reset-email', {
                baseURL,
                method: 'POST',
                body: { email },
            })
            .catch((error) => {
                throw new Error(error.data.detail)
            })
    }

    function resetPassword(userId, code, password) {
        return $fetch
            .raw('/auth/reset-password', {
                baseURL,
                method: 'POST',
                body: { userId, code, password },
            })
            .then((response) => {
                if (response._data.status === 'failed') {
                    throw new Error(response._data.message)
                }
            })
    }

    return {
        // state
        email,
        data,

        // "computed", fetched
        authenticated,
        initialized,
        fetchingAccountData,
        fullName,
        payment,
        contracts,
        invoices,
        pickups,
        customerId,

        // actions
        initialize,
        fetchContracts,
        setReminder,
        editContract,
        cancelContract,
        fetchInvoices,
        fetchPickups,
        fetchAccountData,
        saveAccountData,
        checkAuthentication,
        login,
        logout,
        register,
        resetPasswordSendEmail,
        resetPassword,
    }
})
