<template>
    <span
        :class="['icon', ratio ? 'ratio-' + ratio : '', { button }, className]"
        v-html="component"
    ></span>
</template>

<script>
export default {
    props: {
        name: {
            type: String,
            default: '',
        },
        alt: {
            type: String,
            default: '',
            // required: true,
        },
        ratio: {
            type: [String, Boolean],
            default: 'quad',
        },

        button: {
            type: Boolean,
            default: false,
        },
    },

    setup() {
        return {
            icons: useIcons(),
        }
    },

    computed: {
        component() {
            return this.icons[this.name]
            // const icon = this.icons[`/assets/icons/${this.name}.svg`]
            // return icon ? icon.default : false
            // return defineAsyncComponent(() =>
            //     import(
            //         /* @vite-ignore */ `/src/assets/icons/${this.name}.svg?component`
            //     ),
            // )
        },
        className() {
            return this.name.replace('/', '-')
        },
    },
}
</script>

<style lang="scss">
@use 'sass:math';
@import '@/assets/config';

.icon {
    --icon-transition-time: 0.25s;
    --icon-color: inherit;
    --icon-color-inactive: inherit;

    position: relative;
    display: inline-block;
    width: 1rem;
    height: auto;
    overflow: hidden;
    z-index: 1;
    color: var(--icon-color);
    transform-origin: center;
    @include no-select;

    &.ratio-quad {
        @include aspect-ratio(1);
    }

    &.ratio-16by9 {
        @include aspect-ratio(math.div(16, 9));
    }

    &.scribbles-pow {
        @include aspect-ratio(math.div(45, 46));
    }

    &.scribbles-star1 {
        @include aspect-ratio(math.div(66, 60));
    }

    &.scribbles-star2 {
        @include aspect-ratio(math.div(83, 76));
    }

    &.scribbles-wooosh {
        @include aspect-ratio(math.div(161, 105));
    }

    &.trash1 {
        @include aspect-ratio(math.div(153, 253));
    }

    &.trash2 {
        @include aspect-ratio(math.div(139, 193));
    }

    &.trash3 {
        @include aspect-ratio(math.div(60, 276));
    }

    &.trash4 {
        @include aspect-ratio(math.div(215, 90));
    }

    &.trash5 {
        @include aspect-ratio(math.div(364, 188));
    }

    &.trash6 {
        @include aspect-ratio(math.div(278, 182));
    }

    &.earth {
        @include aspect-ratio(math.div(186, 174));
    }

    &.shirt {
        @include aspect-ratio(math.div(176, 185));
    }

    &.smiley-happy {
        @include aspect-ratio(math.div(183, 179));
    }

    &.smiley-sad {
        @include aspect-ratio(math.div(182, 180));
    }

    &.arrow-click {
        @include aspect-ratio(math.div(180, 184));
    }

    &.fist {
        @include aspect-ratio(math.div(140, 185));
    }

    &.cargo-bike {
        @include aspect-ratio(math.div(494.7, 342.6));
    }

    &.fit-container {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &.inactive {
        opacity: 0.35;
    }

    &.button {
        width: 1.5rem;
        cursor: pointer;
        @include no-select;
        transform: scale(1);
        transition: transform var(--icon-transition-time)
            theme('transitionTimingFunction.easeOutCubic');

        &.light {
            &:hover:not(.disabled),
            &:focus:not(.disabled) {
                color: theme('colors.primary');
            }
        }

        &.disabled {
            cursor: default;
            opacity: 0.35;
        }

        &:hover:not(.disabled),
        &:focus:not(.disabled) {
            color: theme('colors.primary-light');
            transform: scale(1.1);

            &:active {
                transform: scale(0.95);
            }
        }
    }

    svg {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        transition: color var(--icon-transition-time);

        circle,
        rect,
        path {
            transition: fill var(--icon-transition-time),
                stroke var(--icon-transition-time),
                fill-opacity var(--icon-transition-time),
                stroke-opacity var(--icon-transition-time);
        }
    }
}
</style>
