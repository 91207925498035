<template>
    <aside class="sidebar">
        <div class="user">
            <icon name="avatar" />
            <div class="info">
                <div class="name">{{ userStore.fullName || '&nbsp;' }}</div>
                <linky
                    class="white text-white"
                    text-link
                    @click="userStore.logout()"
                    >Abmelden</linky
                >
            </div>
        </div>

        <div class="page-links">
            <butty
                v-for="link in links"
                :key="link.name"
                :to="{ name: link.name }"
                class="ghost small"
                aria-current="page"
                >{{ i18n.routes[link.name] }}</butty
            >
        </div>
    </aside>
</template>

<script>
import useUserStore from '~/stores/user.js'
import routes from '@/routes'

export default {
    inject: ['isUserArea'],

    async setup() {
        const [userStore, i18n] = await Promise.all([useUserStore(), useI18n()])

        return {
            userStore,
            i18n,
        }
    },

    data() {
        const links = routes.filter((item) =>
            ['user-dashboard', 'user-data', 'user-invoices'].includes(
                item.name,
            ),
        )

        return {
            links,
        }
    },

    mounted() {
        if (this.userStore.initialized && !this.userStore.fetchingAccountData) {
            this.userStore.fetchAccountData()
        }
    },
}
</script>

<style lang="scss" scoped>
.sidebar {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    width: var(--user-sidebar-width);
    height: 100%;
    padding: 2rem 1rem;
    background: theme('colors.slate.500');
    color: #fff;

    .text-link {
        --tl-bg-color: theme('colors.primary-light');
        --tl-active-color: theme('colors.primary-dark');
    }
}

.user {
    position: relative;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-bottom: 1px solid theme('colors.slate.300');
    padding: 0 0 1rem;
    margin: 0 0 3rem;

    .icon {
        color: #fff;
        width: 2.5rem;
        height: 2.5rem;
        margin-right: 0.5rem;
    }

    .info {
        display: flex;
        flex-flow: column;
        align-items: flex-start;
        @apply text-sm;
    }

    .text-link {
        @apply text-xs;
        color: theme('colors.slate.200');

        &:hover {
            color: theme('colors.primary-dark');
        }
    }
}

.page-links {
    display: flex;
    flex-flow: column;
    gap: 0.75rem;
    width: 100%;
    text-align: center;
    // align-items: stretch;

    .butty {
        padding: 0;
        // width: 100%;
        // flex: auto 1 0;

        --butty-border-color: transparent;
        --butty-bg-color: transparent;
        --butty-color: #fff;

        &:hover {
            --butty-border-color: theme('colors.primary-light');
            --butty-bg-color: transparent;
            --butty-color: theme('colors.primary-light');
            background-color: var(--butty-bg-color);
            color: var(--butty-color);
        }

        &.router-link-exact-active {
            --butty-border-color: theme('colors.primary-light');
            --butty-bg-color: theme('colors.primary-light');
            --butty-color: theme('colors.primary-dark');
            background-color: var(--butty-bg-color);
            color: var(--butty-color);
        }
    }
}
</style>
