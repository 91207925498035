import arrowClick from '@/assets/icons/arrow-click.svg?raw'
import bag from '@/assets/icons/bag.svg?raw'
import earth from '@/assets/icons/earth.svg?raw'
import fist from '@/assets/icons/fist.svg?raw'
import smileyHappy from '@/assets/icons/smiley-happy.svg?raw'
import smileySad from '@/assets/icons/smiley-sad.svg?raw'
import recyclehero from '@/assets/icons/recyclehero.svg?raw'
import cargoBike from '@/assets/icons/cargo-bike.svg?raw'
import scribblesPow from '@/assets/icons/scribbles-pow.svg?raw'
import scribblesStar1 from '@/assets/icons/scribbles-star1.svg?raw'
import scribblesStar2 from '@/assets/icons/scribbles-star2.svg?raw'
import scribblesWooosh from '@/assets/icons/scribbles-wooosh.svg?raw'
import shirt from '@/assets/icons/shirt.svg?raw'
import trash1 from '@/assets/icons/trash1.svg?raw'
import trash2 from '@/assets/icons/trash2.svg?raw'
import trash3 from '@/assets/icons/trash3.svg?raw'
import trash4 from '@/assets/icons/trash4.svg?raw'
import trash5 from '@/assets/icons/trash5.svg?raw'
import trash6 from '@/assets/icons/trash6.svg?raw'
import trashBackground from '@/assets/icons/trash-background.svg?raw'
import logoFacebook from '@/assets/icons/logo-facebook.svg?raw'
import logoInstagram from '@/assets/icons/logo-instagram.svg?raw'
import logoLinkedIn from '@/assets/icons/logo-linkedin.svg?raw'
import logoTikTok from '@/assets/icons/logo-tiktok.svg?raw'
import x from '@/assets/icons/x.svg?raw'
import arrowUp from '@/assets/icons/arrow-up.svg?raw'
import edit from '@/assets/icons/edit.svg?raw'
import check from '@/assets/icons/check.svg?raw'
import avatar from '@/assets/icons/avatar.svg?raw'
import trashCan from '@/assets/icons/trash-can.svg?raw'
import refresh from '@/assets/icons/refresh.svg?raw'
import documentDownload from '@/assets/icons/document-download.svg?raw'
import bell from '@/assets/icons/bell.svg?raw'
import cart from '@/assets/icons/cart.svg?raw'
import recyclables from '@/assets/icons/recyclables.svg?raw'
import glassTypes from '@/assets/icons/glass-types.svg?raw'
import shoes from '@/assets/icons/shoes.svg?raw'
import calendar from '@/assets/icons/calendar.svg?raw'
import chat from '@/assets/icons/chat.svg?raw'
import mail from '@/assets/icons/mail.svg?raw'
import call from '@/assets/icons/call.svg?raw'
import contact from '@/assets/icons/contact.svg?raw'

const icons = {
    avatar,
    'arrow-click': arrowClick,
    'arrow-up': arrowUp,
    bag,
    bell,
    calendar,
    cart,
    check,
    'document-download': documentDownload,
    earth,
    edit,
    fist,
    'glass-types': glassTypes,
    'smiley-happy': smileyHappy,
    'smiley-sad': smileySad,
    recyclehero,
    'cargo-bike': cargoBike,
    refresh,
    recyclables,
    'scribbles-pow': scribblesPow,
    'scribbles-star1': scribblesStar1,
    'scribbles-star2': scribblesStar2,
    'scribbles-wooosh': scribblesWooosh,
    shirt,
    shoes,
    trash1,
    trash2,
    trash3,
    trash4,
    trash5,
    trash6,
    'trash-background': trashBackground,
    'trash-can': trashCan,
    'logo-facebook': logoFacebook,
    'logo-instagram': logoInstagram,
    'logo-linkedin': logoLinkedIn,
    'logo-tiktok': logoTikTok,
    x,
    contact,
    chat,
    call,
    mail,
}

export function useIcons() {
    return icons
}
