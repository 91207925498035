<template>
    <footer class="footer bg-slate-900 max-w-screen-2xl mx-auto">
        <div class="background"></div>

        <div class="content">
            <div class="logo-wrapper">
                <logo />
            </div>

            <div class="cols">
                <ul v-if="type === 'internal'" class="col">
                    <li class="title">Wertstoffe</li>
                    <li v-for="link in links.landingPages" :key="link.name">
                        <linky :to="{ name: link.name }" class="white">{{
                            i18n.routes[link.name]
                        }}</linky>
                    </li>
                    <!-- external link-->
                </ul>
                <ul v-if="type === 'internal'" class="col">
                    <li class="title">Seiten</li>
                    <li v-for="link in links.pages" :key="link.name">
                        <linky :to="{ name: link.name }" class="white">{{
                            i18n.routes[link.name]
                        }}</linky>
                    </li>
                    <!-- external link-->
                </ul>
                <ul class="col">
                    <li class="title">Kontakt</li>
                    <li v-for="link in links.contact" :key="link.name">
                        <linky :to="{ name: link.name }" class="white">{{
                            i18n.routes[link.name]
                        }}</linky>
                    </li>
                    <!-- external link-->
                    <li>
                        <linky
                            :to="'https://info.recyclehero.de/presse'"
                            class="white"
                            >Presse
                        </linky>
                    </li>
                    <li>
                        <linky
                            :to="'https://info.recyclehero.de/altkleider-versenden'"
                            class="white"
                            >Unser Versandservice
                        </linky>
                    </li>
                    <li v-if="type === 'internal'">
                        <linky
                            :to="'https://info.recyclehero.de/b2b-loesungen'"
                            class="white"
                            >Lösungen für Unternehmen
                        </linky>
                    </li>
                    <li>
                        <linky
                            :to="'https://info.recyclehero.de/sammelstelle-altkleider-sammlung'"
                            class="white"
                            >Sammelstellen
                        </linky>
                    </li>
                    <li>
                        <linky
                            :to="'https://info.recyclehero.de/newsletter'"
                            class="white"
                            >Newsletter
                        </linky>
                    </li>
                </ul>
                <ul class="col">
                    <li class="title">Legal</li>
                    <li v-for="link in links.legal" :key="link.name">
                        <linky :to="{ name: link.name }" class="white">{{
                            i18n.routes[link.name]
                        }}</linky>
                    </li>
                    <li>
                        <linky
                            :to="{
                                name: 'faq',
                                query: { q: 'zahlungs-und-erbringungsgebiet' },
                            }"
                            class="white ignore-router-link-active"
                            >Zahlung und Erbringungsgebiet</linky
                        >
                    </li>
                </ul>
            </div>

            <div class="end">
                <social-media />
            </div>
        </div>
    </footer>
</template>

<script>
import routes from '@/routes'

export default {
    props: {
        type: {
            type: String,
            default: 'internal',
            validator: (value) => ['internal', 'external'].includes(value),
        },
    },

    setup() {
        return {
            i18n: useI18n(),
        }
    },

    data() {
        const legal = routes.filter((item) =>
            ['terms', 'privacy', 'legal'].includes(item.name),
        )

        const contact = routes.filter((item) =>
            ['contact', 'imprint'].includes(item.name),
        )

        const landingPages = routes.filter((item) =>
            ['old-clothes', 'electronics', 'handys'].includes(item.name),
        )

        const pages = routes.filter((item) =>
            ['team', 'social-projects', 'jobs', 'blog', 'faq'].includes(
                item.name,
            ),
        )

        return {
            links: {
                pages,
                legal,
                contact,
                landingPages,
            },
        }
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/config';

.footer {
    position: relative;
    padding: 3rem var(--section-padding-x);
    color: #fff;
    overflow: hidden;
}

.title,
.copyright {
    @apply text-sm;
    @apply font-semibold;
    @apply font-sans;
    color: theme('colors.slate.200');
    opacity: 1;
    text-transform: uppercase;
}

.content {
    position: relative;
    z-index: 10;
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: space-around;
    gap: 0;
    width: 100%;
}

.logo-wrapper {
    width: 100%;
    margin: 0 0 1.5rem;

    .logo {
        margin: 0 auto;
    }
}

.cols {
    display: flex;
    flex-flow: row wrap;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    width: 100%;
}

.col {
    width: 100%;
    text-align: center;
    margin-bottom: 2rem;
    margin-top: 2rem;
    li {
        margin-bottom: 0.75rem;
    }
}

.end {
    margin-top: 1rem;
}

.social-media {
    margin-bottom: 0.75rem;
    justify-content: center;
}

.background {
    position: absolute;
    @apply inset-0;
    z-index: 1;
    overflow: hidden;
    pointer-events: none;

    .icon {
        position: absolute;
        display: block;
    }
}

@screen sm {
    .content {
        // display: flex;
        flex-flow: row wrap;
        // align-items: flex-start;
        // justify-content: space-around;
        gap: 0;
        // width: 100%;
    }

    .logo-wrapper {
        width: auto;
        margin: 0;

        .logo {
            margin: 0;
        }
    }

    .cols {
        width: auto;
        flex: auto 1 1;
    }

    .end {
        display: flex;
        width: 100%;
        align-items: flex-end;
        flex-flow: row nowrap;
        gap: 0;
        margin: 0 0 1.25rem;
        // justify-content: space-between;
    }

    .social-media {
        width: auto;
        margin: 0 auto 0 0;
        flex: auto 0 1;
        gap: 1rem;

        :deep(.icon) {
            width: 2rem;
        }
    }

    .copyright {
        width: auto;
        margin: 0 0 0 auto;
        flex: auto 0 0;
    }

    .background {
    }
}

@screen md {
    .content {
        align-items: stretch;
    }

    .cols {
        width: 100%;
        margin-right: 0;
        flex-direction: row;
    }

    .col {
        width: 50%;
    }

    .end {
        flex-flow: column nowrap;
        width: auto;
        // width: 100%;
        // align-items: flex-end;
        // gap: 0;
        margin: 0;
        // justify-content: space-between;
    }

    .social-media {
        width: auto;
        justify-content: flex-start;
        margin: 0 auto auto 0;
        // flex: auto 0 1;
        gap: 1rem;
    }

    .copyright {
        margin: auto 0 0;
        flex: none;
    }

    .background {
    }
}

@screen lg {
    .content {
        gap: 0.75rem;
    }

    .logo-wrapper {
        margin-right: auto;
    }

    .cols {
        width: 50%;
        flex: auto 0 0;
    }

    .col {
        text-align: left;
    }

    .background {
    }
}

@screen xl {
    .cols {
        width: 45%;
        margin-right: 5rem;
    }

    .background {
    }
}
</style>
