export default defineNuxtPlugin((nuxtApp) => {
    const config = useRuntimeConfig()
    const isProduction = config.public.env === 'production'
    const isStaging = config.public.env === 'staging'

    nuxtApp.vueApp.config.unwrapInjectedRef = true

    return {
        provide: {
            mode: {
                isProduction,
                isStaging,
                isDevelopment: !isProduction && !isStaging,
            },
        },
    }
})
