import { plugin, defaultConfig } from '@formkit/vue'
import { de } from '@formkit/i18n'
import '@formkit/themes/genesis'

import _clone from 'lodash/clone.js'

// icons
// import { inputIcons } from '@formkit/icons'
// import check from '@/assets/icons/check.svg?raw'

const optionCheckedPlugin = (node) => {
    if (
        node.type === 'input' &&
        node.props.type === 'radio' /* || node.props.type === 'checkbox' */
        // node.props.options
    ) {
        const definition = _clone(node.props.definition)
        node.props.definition.schema = definition.schema({
            option: {
                attrs: {
                    'data-checked': '$fns.isChecked($option.value)',
                },
            },
        })
    }
}

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.use(
        plugin,
        defaultConfig({
            theme: 'genesis',
            locales: { de },
            locale: 'de',
            icons: {
                // ...inputIcons,
                // check,
            },
            plugins: [optionCheckedPlugin],
        }),
    )
})
