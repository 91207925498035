<template>
    <transition
        name="zoom-fade-loader"
        appear
        @leave="hideInner = true"
        @enter="delayedShowInner"
        @after-leave="reset"
    >
        <div v-show="run" :class="['loader', { running: run }]">
            <div :class="['inner', { show: showInner, hide: hideInner }]">
                <span class="bike" v-html="bike"></span>
                <div class="text">Loading</div>
            </div>
        </div>
    </transition>
</template>

<script>
import bike from '@/assets/icons/cargo-bike.svg?raw'

export default {
    props: {
        run: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            bike,
            showInner: false,
            hideInner: false,
        }
    },

    methods: {
        reset() {
            this.showInner = false
            this.hideInner = false
        },

        delayedShowInner() {
            setTimeout(() => {
                this.showInner = true
            }, 50)
        },
    },
}
</script>

<style scoped lang="scss">
@use 'sass:math';
@import '@/assets/config';

$ratio: math.div(494.7, 342.6);

.loader {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    background-color: theme('colors.gray.600' / 60%);
    z-index: 5000;

    &.inline {
        position: relative;
        top: initial;
        left: initial;
        right: initial;
        bottom: initial;
        margin: 0 1.5rem;
        width: 3rem;
        height: math.div(3, $ratio) * 1rem;
        background-color: transparent;

        .inner {
            position: absolute;
            top: 50%;
            left: 50%;
        }

        :deep(.bike) {
            svg {
                width: 3rem;
                height: math.div(3, $ratio) * 1rem;

                g {
                    fill: theme('colors.primary-dark');
                }
            }
        }

        .text {
            display: none;
        }
    }

    .inner {
        position: absolute;
        top: calc(50% + var(--nav-height) / 2);
        left: 50%;
        display: block;
        margin: 0;
        z-index: 5;
        pointer-events: none;
        opacity: 0;
        transform-origin: center center;
        transform: translate(-125%, -50%);
        transition: opacity 0.4s 0s,
            transform 0.4s 0s theme('transitionTimingFunction.easeOutCubic');

        &.show {
            opacity: 1;
            transform: translate(-50%, -50%);

            &.hide {
                opacity: 0;
                transform: translate(25%, -50%);
            }
        }
    }

    :deep(.bike) {
        display: block;

        svg {
            position: relative;
            display: block;
            width: 8rem;
            height: math.div(8, $ratio) * 1rem;

            g {
                fill: theme('colors.primary-light');
            }
        }

        .wheel.front,
        .wheel.back {
            transform-box: fill-box;
            transform-origin: center;
            animation: rotate-wheel 1.4s infinite linear;
        }
    }

    .text {
        display: block;
        width: 100%;
        @apply font-display;
        font-weight: 500;
        text-align: center;
        letter-spacing: 2px;
        text-transform: uppercase;
        color: theme('colors.primary-light');
        @include no-select;
    }
}
</style>

<style lang="scss">
@keyframes rotate-wheel {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.zoom-fade-loader-enter-from,
.zoom-fade-loader-leave-to {
    opacity: 0;
}

.zoom-fade-loader-enter-to,
.zoom-fade-loader-leave-from {
    opacity: 1;
}

.zoom-fade-loader-enter-active {
    transition: opacity 0.4s 0s;
}

.zoom-fade-loader-leave-active {
    transition: opacity 0.4s 0s;
}
</style>
