<template>
    <div :class="`social-media`">
        <a
            v-if="facebook"
            :href="urls.facebook.url"
            rel="noopener"
            target="_blank"
            :alt="urls.facebook.alt">
            <icon name="logo-facebook" role="img" aria-label="Logo Facebook" />
        </a>
        <a
            v-if="linkedin"
            :href="urls.linkedin.url"
            rel="noopener"
            target="_blank"
            :alt="urls.linkedin.alt">
            <icon name="logo-linkedin" role="img" aria-label="Logo LinkedIn" />
        </a>
        <a
            v-if="instagram"
            :href="urls.instagram.url"
            rel="noopener"
            target="_blank"
            :alt="urls.instagram.alt">
            <icon name="logo-instagram" role="img" aria-label="Logo Instagram" />
        </a>
        <a
            v-if="tiktok"
            :href="urls.tiktok.url"
            rel="noopener"
            target="_blank"
            :alt="urls.tiktok.alt">
            <icon name="logo-tiktok" role="img" aria-label="Logo Tiktok" />
        </a>
    </div>
</template>

<script>
    export default {

        props: {
            urlToShare: String,
            instagram: { type: Boolean, default() { return true } },
            facebook: { type: Boolean, default() { return true } },
            linkedin: { type: Boolean, default() { return true } },
            tiktok: { type: Boolean, default() {return true} }
        },

        async setup(props) {
            // TODO: this should be inside a config or env file
            const urls = reactive({
                facebook: {
                    url: 'https://www.facebook.com/recyclehero-355308871946939/',
                    alt: 'Facebook'
                },
                linkedin: {
                    url: 'https://www.linkedin.com/company/recyclehero/',
                    alt: 'LinkedIn'
                },
                instagram: {
                    url: 'https://www.instagram.com/recycle.hero/',
                    alt: 'Instagram'
                },
                tiktok: {
                    url: 'https://www.tiktok.com/@recycle.hero',
                    alt: 'Tiktok'
                }
            });
            // TODO: this should be inside a config or env file
            // These urls will create a post from the logged in user on the specific platform
            const shareUrls = {
                facebook: 'https://www.facebook.com/sharer/sharer.php?u=',
                linkedin: 'https://www.linkedin.com/shareArticle?mini=true&url=',
            };


            /**
             * Overwrite urls if necessary.
             * TODO: Technically this is a moment too late, but I'm 
             * sure that nobody will ever see it.
             */
            onMounted(() => {

                if(props.urlToShare !== undefined) {
                    urls.facebook.url = shareUrls.facebook + props.urlToShare;
                    urls.linkedin.url = shareUrls.linkedin + props.urlToShare;
                }

            }); // eo onMounted()


            return {
                urls
            };
        },
    }
</script>


<style lang="scss" scoped>
.social-media {
    display: flex;
    flex-flow: row nowrap;
    gap: 0.75rem;
    align-items: center;

    &.dark {
        :deep(.icon) {
            &:hover {
                path {
                    fill: theme('colors.primary');
                }
            }

            path {
                fill: theme('colors.slate.900');
            }
        }
    }
}

:deep(.icon) {
    width: 1.75rem;

    &:hover {
        path {
            fill: theme('colors.primary-light');
        }
    }

    path {
        fill: #fff;
    }
}
</style>
