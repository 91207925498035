import useUserStore from '~/stores/user.js'

function handleNavigation(to, from, authenticated) {
    if (!authenticated) {
        if (from.name === 'login') {
            return abortNavigation()
        }

        return navigateTo({ name: 'login', query: { redirect: to.fullPath } })
    }
}

export default defineNuxtRouteMiddleware(async (to, from) => {
    if (!to.name || to.name.substring(0, 5) !== 'user-') {
        return
    }

    const userStore = await useUserStore()

    if (userStore.authenticated === undefined) {
        // use client cookies for server request
        const headers = useRequestHeaders(['cookie'])
        return userStore
            .checkAuthentication({ headers })
            .then((authenticated) => {
                return handleNavigation(to, from, authenticated)
            })
    }

    return handleNavigation(to, from, userStore.authenticated)
})
